import React, { useState } from "react";
import styles from "./Profile.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { movieBaseUrl } from "../../Api-config/movie";
import axios from "axios";
import { Col, Modal, Row } from "react-bootstrap";
import { baseUrl } from "../../Api-config/auth";
import { generateTokenHandler } from "../../utils/json-web";
import { ButtonLoader } from "../../components/ButtonLoaders/ButtonLoader";
export const DeleteAccount = ({ getToast, getDeviceType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let token = localStorage.getItem("authUser");
  const [loading, setLoading] = useState(false);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }

  const handleDelete = async () => {
    setLoading(true);
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,

      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log(
      "headers--------------from get User Detials",
      JSON.stringify(headers)
    );
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: baseUrl + "api/viewerApp/deleteAccount",
      headers: headers,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);

        if (res.data.status) {
          navigate("/account-deletion");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your Session Has Expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(
            t("Something went wrong on our end. Please try again later."),
            "top-right",
            "error"
          );
        }
      });
  };
  return (
    <div className={styles.delete_acccountbox}>
      <h3>{t("Delete Your Account")}</h3>

      <h6>
        Are you sure you want to delete your account? This action is permanent
        and cannot be undone.
      </h6>

      <h6>
        If you're sure you want to delete your account, tap Delete Account
        below.
      </h6>
      <div className={styles.delete_account_btn}>
        <button onClick={handleDelete}>
          {" "}
          {loading ? <ButtonLoader /> : "Delete Account"}
        </button>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        centered
      >
        <Modal.Header className={styles.price_modal_container}>
          <Row>
            <Col xs="auto">
              <Modal.Title> Are you sure</Modal.Title>
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <svg
                viewBox="-0.5 0 25 25"
                fill="none"
                style={{ cursor: "pointer" }}
                width={"20px"}
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleClose()}
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M3 21.32L21 3.32001"
                    stroke="#017bf4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M3 3.32001L21 21.32"
                    stroke="#017bf4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </Col>
          </Row>
          <h6> you want to delete it?</h6>
          <div className={styles.btn_group}>
            <div className={styles.delete_account_btn}>
              <button onClick={handleDelete}>Delete Account</button>
            </div>
            <div className={styles.close_btn}>
              <button onClick={handleClose}>Close</button>
            </div>
          </div>
        </Modal.Header>
      </Modal>
    </div>
  );
};
