import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDataError, getDataSuccess } from "../redux/dataReducer/action";
import "./styles/search.css";
import { useSelector, useDispatch } from "react-redux";
import SlickSlider from "../components/DashboardSlider/Slider/Slider";
import no_result from "../assets/images/no_result_found.png";
import { movieBaseUrl } from "../Api-config/movie";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ContentList } from "../components/contentList/ContentList";
import { generateTokenHandler } from "../utils/json-web";
export const Search = ({ getToast, getDeviceType }) => {
  const { searchText } = useParams();
  const [movies, setMovies] = useState([]);
  const { t } = useTranslation();
  const { data } = useSelector((store) => store.dataReducer);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("authUser");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const urlParams = new URLSearchParams(window.location.search);
  const q = urlParams.get("q");
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }
  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }

  // get the search results
  const searchResult = async () => {
    let slug = await generateTokenHandler();

    let headers = {
      authorization: token,
      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }

    const config = {
      method: "post",
      url: movieBaseUrl + "search.v1",
      headers: headers,
      data: {
        searchText: q,
      },
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);

        if (res.data.status) {
          setMovies(res?.data?.data || []);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your Session Has Expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(
            t("Something went wrong on our end. Please try again later."),
            "top-right",
            "error"
          );
        }
      });
  };

  useEffect(() => {
    searchResult(q);
  }, [q]);

  if (loading) {
    return (
      <div className="loader_box">
        <span class="loader"></span>
      </div>
    );
  }

  if (movies?.length == 0) {
    return (
      <div className="no_result_box">
        <img src={no_result} alt="no-result-found" />
        <h3>No Result Found</h3>
      </div>
    );
  }

  return (
    <div className="search_box">
      <h3>Search Results Related to {q}</h3>
      <div className="search_results">
        {/* <SlickSlider movieData={movies} /> */}
        <ContentList data={movies} />
      </div>
    </div>
  );
};
