import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import { useTranslation } from "react-i18next";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = ({ category, handleClick, isOpen }) => {
  const { t } = useTranslation();
  const navItems = [
    { category_slug: "all", item: t("All") },
    { category_slug: "movie", item: t("Movies") },
    { category_slug: "documentary", item: t("Documentaries") },
    { category_slug: "tvSeries", item: t("Tv Serie") },
    { category_slug: "miniSeries", item: t("Mini-series/ Shorts") },
    { category_slug: "podcast", item: t("Podcasts") },
    { category_slug: "standUp", item: t("Stand up") },
  ];
  return (
    <motion.ul
      variants={variants}
      style={{
        zIndex: "4",
        display: !isOpen ? "none" : "",
        transition: "display 1s",
      }}
      className="ul"
    >
      {navItems?.map((el, i) => (
        <MenuItem
          {...el}
          key={i}
          isOpen={isOpen}
          category={category}
          handleClick={handleClick}
        />
      ))}
    </motion.ul>
  );
};

// const itemIds = ["All", "Movie", "Documentary","Series"];

{
  /* <div className={styles.top_nav_item}>
<span
  className={category_slug == "all" ? styles.selected_nav_item : ""}
  onClick={() => handleClick("all")}
>
  {t("All")}
</span>
<span
  className={category_slug == "movie" ? styles.selected_nav_item : ""}
  onClick={() => handleClick("movie")}
>
  {t("Movies")}
</span>
<span
  className={
    category_slug == "documentary" ? styles.selected_nav_item : ""
  }
  onClick={() => handleClick("documentary")}
>
  {
</span>
<span
  onClick={() => handleClick("tvSeries")}
  className={
    category_slug == "tvSeries" ? styles.selected_nav_item : ""
  }
>
  {t("Tv Series")}
</span>
<span
  onClick={() => handleClick("miniSeries")}
  className={
    category_slug == "miniSeries" ? styles.selected_nav_item : ""
  }
>
  {t("Mini Series")}
</span>
<span
  onClick={() => handleClick("podcast")}
  className={category_slug == "podcast" ? styles.selected_nav_item : ""}
>
  {t("Podcasts")}
</span>
<span
  onClick={() => handleClick("miniSeries")}
  className={category_slug == "standUp" ? styles.selected_nav_item : ""}
>
  {t("Stand up")
</span>
</div> */
}
