import * as jose from "jose";

export async function generateTokenHandler() {
  const cpc_xsmdkx_mdmdkd = new TextEncoder().encode(
    "2182312c81187ab82bbe053df6b7aa55"
    // "2182zy64mc64nswkemzmcbvjlaie44bd8cdkhsg312c81187ab82bbe053df6b7aa55"
  );
  try {
    const payload = {
      role: "user",
    };

    const jwt = await new jose.SignJWT(payload)
      .setProtectedHeader({ alg: "HS256" })
      .setExpirationTime("5 minutes")
      .sign(cpc_xsmdkx_mdmdkd);
    return jwt;
  } catch (error) {
    console.error("Error  in generating---", error);
    throw error;
  }
}

export const captchakey = "6Lf7d18mAAAAAMjtQQgvT5Oe4V3q3OxhI94zPpuu";

// test
export const gumletCollectionId = "658414dd064278d81eef108a";

// prod
// export const gumletCollectionId = "6582d83f064278d81ee4f422";

export const noOfAds = {
  pre: 1,
  mid: 10,
};
