import React, { useState, useTransition } from "react";
import styles from "./Rating.module.css";
import "./star.css";
import StarRating from "./StarRating";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { movieBaseUrl } from "../../Api-config/movie";
import axios from "axios";
import { generateTokenHandler } from "../../utils/json-web";
export const Rating = ({ getToast, getDeviceType }) => {
  const [selectedRating, setSelectedRating] = useState(2.5);
  const { t } = useTranslation();
  const [rating, setRating] = useState(1);

  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  // getting token
  const authUser = localStorage.getItem("authUser");

  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }
  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }

  const handleSelectedRating = (e) => {
    setSelectedRating(e.target.value);
  };

  const handleSubmitRating = async () => {
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log("headers", headers);
    let data = {
      videoId: sessionStorage.getItem("videoRated"),
      transactionId: sessionStorage.getItem("transcRated"),
      rating: rating,
    };

    if (comment) {
      data.comment = comment;
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `/userEngagement.v1`,
      headers: headers,
      data: data,
    };
    axios
      .request(config)
      .then((res) => {
        console.log("detailed data", res?.data?.data);
        if (res?.data?.status && res?.data?.response_code == 200) {
          getToast(res?.data?.message, "top-right", "success");
          // getFavouritesList();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          // getToast(
          //   t("Your token is expired. Please login again."),
          //   "top-right",
          //   "error"
          // );
        } else {
          getToast(
            t("Something went wrong on our end. Please try again later."),
            "top-right",
            "error"
          );
        }
      });
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  return (
    <div>
      <div className={styles.rating_container}>
        <h2>
          {t(
            "Become a movie critic by leaving a review and creating your own playlist"
          )}
        </h2>

        <h6>{t("What did you think about the movie you just watched?")}</h6>

        <StarRating rating={rating} onRatingChange={handleRatingChange} />
        <textarea
          name=""
          id=""
          onChange={(e) => setComment(e.target.value)}
          rows="4"
          placeholder="Leave a review here"
        ></textarea>
        <button onClick={handleSubmitRating} className={styles.follow_btn}>
          {t("Submit")}
        </button>
      </div>
    </div>
  );
};
