import React, { useEffect, useState } from "react";
import styles from "./BuyTickets.module.css";
import { useTranslation } from "react-i18next";
import { isRouteErrorResponse, useNavigate } from "react-router-dom";
import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { movieBaseUrl } from "../../../Api-config/movie";
import axios from "axios";

import { v4 as uuidv4 } from "uuid";
import { generateTokenHandler } from "../../../utils/json-web";
import { checkPaymentStatusForSeasonOrMovies } from "../../../utils/checkContentPurchaseStatus";

export const BuyTickets = ({
  detailedData,
  getToast,
  deviceDetailsFromApp,
  paymentStatus = {},
  getDeviceType,
  seasonNumber,
  episode,
}) => {
  const { devicePricing, isSeries, seasons, category, videoId, isAvod } =
    detailedData;

  const [price, setPrice] = useState(devicePricing);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [showAdModal, setShowAdModal] = useState(false);

  const [showPaymentOptions, setPaymentOptions] = useState(false);
  const handleClosePaymentOptionModal = () => {
    setPaymentOptions(false);
  };
  // const deviceDetailsFromApp = parseCustomJsonString(
  //   window.deviceDetails || localStorage.getItem("deviceDetails")
  // );
  // getting favourites from storage;
  let favourites = JSON.parse(localStorage.getItem("favourites")) || [];

  const [isFav, setIsFav] = useState(
    favourites.includes(detailedData?.videoId)
  );

  const [currentSeason, setCurrentSeason] = useState(
    isSeries
      ? seasons?.find((item) => item?.seasonNumber == seasonNumber) || {}
      : {}
  );

  useEffect(() => {
    setCurrentSeason(
      isSeries
        ? seasons?.find((item) => item?.seasonNumber == seasonNumber) || {}
        : {}
    );
    console.log("Season Number", seasonNumber);
  }, [seasonNumber]);
  console.log("currentSeason: ", currentSeason);

  const { t } = useTranslation();
  const [selectedPricing, setSelectingPricing] = useState("groups");
  const authUser = localStorage.getItem("authUser");

  const navigate = useNavigate();
  const [showPriceModal, setPriceModal] = useState(false);
  const [currentDevicePrice, setCurrentDevicePrice] = useState("");
  const [walletload, setWalletload] = useState(false);
  const [resumeViewerShipAds, setResumeViewerShipAds] = useState("");

  const handleClosePriceModal = () => {
    setPriceModal(false);
  };
  const userAgent = window.navigator.userAgent.toLowerCase();

  const openViewerShipScreen = (
    type,
    viewType,
    priceType,
    amount,
    payDevice
  ) => {
    // viewer Id

    const viewerId = localStorage.getItem("viewerId");
    sessionStorage.setItem("primaryDevice", "yes");
    const detailsForPayScreen = {
      viewType,
      priceType,
      payDevice,
      amount,
      isSeries: isSeries,
      category: category,
      episode: isSeries && episode ? episode : null,
      deviceType: getDeviceType(),
      // seasonNumber: isSeries ? currentSeason?.seasonNumber : null,
      seasonNumber: isSeries && seasonNumber ? seasonNumber : null,
      videoId: detailedData.videoId,
    };
    console.log(detailsForPayScreen);
    // setSelectingPricing(type);
    setPriceModal(false);
    console.log("going to flutter app", type);
    const messageData = {
      authUser,
      detailsForPayScreen,
      deviceType: getDeviceType(),
      detailedData,
    };
    if (priceType == "ppe") {
      console.log("navigating to ppe", priceType);
      sessionStorage.setItem("watchType", "ppe");
      messageData.viewerId = viewerId;

      var jsonString = JSON.stringify(messageData);
      var encodedJsonString = encodeURIComponent(jsonString);
      console.log("encodedJsonString: ", encodedJsonString);
      console.log(
        "deviceDetailsFromApp?.deviceId: ",
        deviceDetailsFromApp?.deviceId
      );
      window?.flutter_inappwebview?.callHandler("navigateToppe", messageData);
      if (
        deviceDetailsFromApp?.deviceId &&
        (messageData.deviceType == "laptop" || messageData.deviceType == "tv")
      ) {
        window.location.href = `app://ppe?data=${encodedJsonString}`;
      }
    } else if (priceType == "watchParty") {
      console.log("navigating to watchParty", priceType);
      sessionStorage.setItem("watchType", "watchParty");
      messageData.viewerId = viewerId;

      var jsonString = JSON.stringify(messageData);
      var encodedJsonString = encodeURIComponent(jsonString);
      console.log(
        "deviceDetailsFromApp?.deviceId: ",
        deviceDetailsFromApp?.deviceId
      );
      window?.flutter_inappwebview?.callHandler(
        "navigateTowatchParty",
        messageData
      );
      if (
        deviceDetailsFromApp?.deviceId &&
        (messageData.deviceType == "laptop" || messageData.deviceType == "tv")
      ) {
        window.location.href = `app://navigateTowatchParty?data=${encodedJsonString}`;
      }
    }
  };

  const handleClickPrice = (type, viewType, priceType, amount, payDevice) => {
    console.log("type: ", type);
    // setSelectingPricing(type);
    if (type == "individual") {
      if (!deviceDetailsFromApp) {
        // getToast(t("This feature is coming soon."), "top-right", "info");
        return;
      }
      return;
    }

    // openViewerShipScreen("individual", viewType, priceType, amount, payDevice);
    setPaymentOptions(true);
    // handlePayWatchParty(1, "watchParty", amount, payDevice);

    // setPriceModal(true);
    // navigate("/buy_tickets/add_buddy");
  };

  // for add viewer to viewer ship
  const handleAddViewer = async (viewerShipId, viewerId) => {
    const slug = await generateTokenHandler();
    try {
      let headers = {
        authorization: authUser,
        devicetype: getDeviceType(),
        slug: slug,
      };
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: movieBaseUrl + "addViewer/" + viewerShipId + "/connected",
        headers: headers,
      };
      axios.request(config).then((res) => {
        console.log(res.data);
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  // handle pay for watchparty
  const handlePayWatchParty = async (
    viewType,
    priceType,
    amount,
    payDevice
  ) => {
    const viewerId = localStorage.getItem("viewerId");
    const viewerShipId = uuidv4();
    setWalletload(true);
    handleAddViewer(viewerShipId, viewerId);
    let slug = await generateTokenHandler();
    try {
      let headers = {
        authorization: authUser,
        slug: slug,
        devicetype: getDeviceType(),
      };
      const data = {
        viewerShipId: viewerShipId,
        viewerIds: [viewerId],
        amount: amount,
        videoId: videoId,
        transactionDate: new Date(),
        videoDetails: {
          viewType: 1,
          priceType: isSeries ? "watchPartySeason" : "watchParty",
          amount: amount,
          payDevice: payDevice,
          videoId: videoId,
          isSeries: isSeries,
          deviceType: payDevice,
          episodeNumber: isSeries && episode ? episode : null,
          seasonNumber: isSeries && seasonNumber ? seasonNumber : null,
          numberOfViewers: 1,
        },
      };
      console.log("data: ", data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: movieBaseUrl + "pay_page",
        headers: headers,
        data: data,
      };
      axios
        .request(config)
        .then((res) => {
          console.log("res: ", res.data.data);
          if (res.data.status && res.data.response_code == 200) {
            getToast(res.data.message, "top-right", "success");
            const reddirectUrl =
              res.data["data"]["data"]["instrumentResponse"]["redirectInfo"][
                "url"
              ];

            sessionStorage.setItem(
              "transactionId",
              res.data["data"]["data"]["merchantTransactionId"]
            );
            console.log("reddirectUrl: ", reddirectUrl);
            sessionStorage.setItem("videoDetails", videoId);
            sessionStorage.setItem("viewerShipId", viewerShipId);
            if (isSeries) {
              sessionStorage.setItem("seasonNumber", seasonNumber);
              sessionStorage.setItem("episodeNumber", 1);
            }
            localStorage.setItem("playWithAds", false);
            localStorage.setItem(
              "deviceDetails",
              JSON.stringify({
                deviceType: getDeviceType(),
              })
            );
            // sessionStorage.setItem("watchType", "watchParty");
            sessionStorage.setItem(
              "watchType",
              isSeries ? "watchPartySeason" : "watchParty"
            );
            setWalletload(false);
            window.location.href = reddirectUrl;
            // navigate("/paymentPending");
          }
        })
        .catch((err) => {
          setWalletload(false);
          if (err?.response?.data?.response_code == 401) {
            navigate("/signin");
            localStorage.clear();
            getToast(
              t("Your token is expired. Please login again."),
              "top-right",
              "error"
            );
          } else {
            getToast(err?.response?.data?.message, "top-right", "error");
          }
        });
    } catch (error) {
      setWalletload(false);
      console.log("error: ", error);
    }
  };

  // handle paye for watchparty via wallet
  const handlePayWatchPartyFromWallet = async (amount, payDevice) => {
    const viewerId = localStorage.getItem("viewerId");
    const viewerShipId = uuidv4();
    setWalletload(true);
    handleAddViewer(viewerShipId, viewerId);
    let slug = await generateTokenHandler();
    try {
      let headers = {
        authorization: authUser,
        devicetype: getDeviceType(),
        slug: slug,
      };
      const data = {
        viewerShipId: viewerShipId,
        viewerIds: [viewerId],
        amount: amount,
        videoId: videoId,
        transactionDate: new Date(),
        videoDetails: {
          viewType: 1,
          priceType: isSeries ? "watchPartySeason" : "watchParty",
          amount: amount,
          payDevice: payDevice,
          deviceType: payDevice,
          videoId: videoId,
          isSeries: isSeries,
          // episode: null,
          // seasonNumber: null,
          episodeNumber: isSeries && episode ? episode : null,
          seasonNumber: seasonNumber && isSeries ? seasonNumber : null,
          numberOfViewers: 1,
        },
      };
      console.log("data: ", data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: movieBaseUrl + "pay/wallet",
        headers: headers,
        data: data,
      };
      axios
        .request(config)
        .then((res) => {
          console.log("res: ", res.data.data);
          if (res.data.status && res.data.response_code == 200) {
            getToast(res.data.message, "top-right", "success");

            sessionStorage.setItem("videoDetails", videoId);
            sessionStorage.setItem("viewerShipId", viewerShipId);
            if (isSeries) {
              sessionStorage.setItem("seasonNumber", seasonNumber);
              sessionStorage.setItem("episodeNumber", 1);
            }
            localStorage.setItem("playWithAds", false);
            localStorage.setItem(
              "deviceDetails",
              JSON.stringify({
                deviceType: getDeviceType(),
              })
            );
            // sessionStorage.setItem("watchType", "watchParty");
            sessionStorage.setItem(
              "watchType",
              isSeries ? "watchPartySeason" : "watchParty"
            );

            setWalletload(false);
            navigate("/paymentSuccess");
          }
        })
        .catch((err) => {
          setWalletload(false);
          if (err?.response?.data?.response_code == 401) {
            navigate("/signin");
            localStorage.clear();
            getToast(
              t("Your token is expired. Please login again."),
              "top-right",
              "error"
            );
          } else {
            getToast(err?.response?.data?.message, "top-right", "error");
          }
        });
    } catch (error) {
      setWalletload(false);
      console.log("error: ", error);
    }
  };

  // handle add favourite
  const handleAddFavourite = async () => {
    console.log("add to fav--------");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    console.log(headers.authorization);
    console.log(headers.devicetype);
    console.log(deviceDetailsFromApp);
    console.log(deviceDetailsFromApp?.deviceId);

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + "favourite/add",
      headers: headers,
      data: {
        videoId: detailedData?.videoId,
      },
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        if (res.data.status && res.data.response_code == 200) {
          getToast(res.data.message, "top-right", "success");

          favourites.push(detailedData?.videoId);

          localStorage.setItem("favourites", JSON.stringify(favourites));
          setIsFav(true);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  // handleresume watching
  const handleResumeWatching = () => {
    const messageData = {
      ...paymentStatus,
      videoId,
      payDevice: paymentStatus.deviceType,
    };
    sessionStorage.setItem("videoDetails", videoId);
    sessionStorage.setItem("viewerShipId", paymentDetails?.viewerShipId);
    if (isSeries) {
      sessionStorage.setItem("seasonNumber", seasonNumber);
      sessionStorage.setItem("episodeNumber", 1);
    }
    localStorage.setItem("playWithAds", false);
    localStorage.setItem(
      "deviceDetails",
      JSON.stringify({
        deviceType: getDeviceType(),
      })
    );
    // sessionStorage.setItem("watchType", "watchParty");
    sessionStorage.setItem(
      "watchType",
      isSeries ? "watchPartySeason" : "watchParty"
    );

    navigate("/paymentSuccess");
  };

  // get the priceType according to device
  const getPriceAccordingToDevice = (watchType) => {
    const device = getDeviceType();
    if (device == "mobile") {
      if (isSeries) {
        setPrice(currentSeason?.devicePricing?.mobile);
        console.log(
          "currentSeason?.devicePricing?.mobile: ",
          currentSeason?.devicePricing?.mobile
        );
      } else {
        setPrice(devicePricing?.mobile);
      }
    } else if (device == "laptop") {
      if (isSeries) {
        setPrice(currentSeason?.devicePricing?.web);
        console.log(
          "currentSeason?.devicePricing?.web: ",
          currentSeason?.devicePricing?.web
        );
      } else {
        setPrice(devicePricing?.web);
      }
    } else if (device == "tv") {
      if (isSeries) {
        setPrice(currentSeason?.devicePricing?.tv);
        console.log(
          "currentSeason?.devicePricing?.tv: ",
          currentSeason?.devicePricing?.tv
        );
      } else {
        setPrice(devicePricing?.tv);
      }
    }

    console.log("....... price is ", price);
  };
  console.log(window.innerHeight, window.innerWidth);

  // apis for handlking ads
  const handlePayViaAds = async () => {
    console.log("add to fav--------");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    const viewerShipId =
      resumeViewerShipAds == "" ? uuidv4() : resumeViewerShipAds;
    let data = {
      videoId: videoId,
      viewerShipId: viewerShipId,
      // "location": jsonEncode(location),
      isSeries: isSeries,
      isClicked: false,
      adType: "rewarded",
    };

    if (isSeries) {
      data.seasonNumber = seasonNumber;
      data.episodeNumber = 1;
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + "createWatchedAd",
      headers: headers,
      data,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        if (res.data.status && res.data.response_code == 200) {
          sessionStorage.setItem("watchType", "watchParty");
          sessionStorage.setItem("videoDetails", videoId);
          localStorage.setItem("playWithAds", true);
          sessionStorage.setItem("viewerShipId", viewerShipId);
          localStorage.setItem(
            "deviceDetails",
            JSON.stringify({
              deviceType: getDeviceType(),
            })
          );
          if (isSeries) {
            sessionStorage.setItem("seasonNumber", seasonNumber);
            sessionStorage.setItem("episodeNumber", 1);
          }
          navigate("/paymentSuccess");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          // getToast(
          //   t("Your token is expired. Please login again."),
          //   "top-right",
          //   "error"
          // );
        } else {
          getToast(
            t("Something went wrong on our end. Please try again later."),
            "top-right",
            "error"
          );
        }
      });
  };

  const getStatusViaAds = async () => {
    console.log("get Status via ads--------");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }

    const data = {
      videoId: videoId,
      // "viewerShipId": viewerShipId,
      isSeries: isSeries,
    };

    if (isSeries) {
      data.seasonNumber = seasonNumber;
      data.episodeNumber = 1;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + "getWatchedAdByViewer",
      headers: headers,
      data,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res:getStatus ", res.data.data);
        if (res.data.status && res.data.response_code == 200) {
          setResumeViewerShipAds(res.data.data.viewerShipId);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          // getToast(
          //   t("Your token is expired. Please login again."),
          //   "top-right",
          //   "error"
          // );
        } else {
          // getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  useEffect(() => {
    getPriceAccordingToDevice();
    localStorage.removeItem("deviceDetails");
  }, []);

  useEffect(() => {
    getStatusViaAds();
    getPriceAccordingToDevice();
    localStorage.removeItem("deviceDetails");
  }, [currentSeason]);

  useEffect(() => {
    setPaymentDetails(
      checkPaymentStatusForSeasonOrMovies(
        getDeviceType,
        paymentStatus,
        isSeries,
        seasonNumber,
        episode
      )
    );
  }, [currentSeason, paymentStatus]);

  return (
    <>
      <div className={styles.pricing_container}>
        {paymentDetails ? (
          <div className={styles.resume_btn_box}>
            <button
              className={styles.resume_btn}
              onClick={handleResumeWatching}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="15"
                viewBox="0 0 11 15"
                fill="none"
              >
                <path d="M0 15V2.37805V0L11 7.5L0 15Z" />
              </svg>
              {t("Resume")} (For{" "}
              {paymentStatus?.docs?.find((item) =>
                item?.priceType === isSeries ? "watchPartySeason" : "watchParty"
              )
                ? "Groups"
                : "Individuals"}
              )
            </button>
          </div>
        ) : (
          ""
        )}

        <div className={styles.pricing_box}>
          <h5>{t("Buy Tickets")}</h5>
          <div>
            <OverlayTrigger
              key={"bottom"}
              placement={"bottom"}
              overlay={
                <Tooltip id={`tooltip-${"bottom"}`}>
                  This feature will be coming soon for browser.
                </Tooltip>
              }
            >
              <div
                style={{
                  borderBottomRightRadius:
                    paymentStatus?.priceType == "watchParty" ? "0.5rem" : "",
                }}
                onClick={() =>
                  handleClickPrice(
                    "individual",
                    1,
                    isSeries ? "ppeSeason" : "ppe",
                    isSeries ? price?.ppeSeason : price?.ppe,
                    getDeviceType()
                  )
                }
                className={styles.disabled_box}
              >
                <span>{t("For Individuals")}</span>
                <span className={styles.pricing_text}>
                  {" "}
                  <span>
                    Rs {isSeries ? price?.ppeSeason : price?.ppe}
                  </span>{" "}
                  {t("/per person")}
                </span>
              </div>
            </OverlayTrigger>
            {paymentDetails ? (
              ""
            ) : (
              <div
                style={{
                  borderBottomRightRadius:
                    paymentStatus?.priceType == "ppe" ? "0.5rem" : "",
                }}
                onClick={() =>
                  handleClickPrice(
                    "groups",
                    1,
                    isSeries ? "watchPartySeason" : "watchParty",
                    isSeries ? price?.watchPartySeason : price?.watchParty,
                    getDeviceType()
                  )
                }
                className={
                  selectedPricing == "groups" ? styles.selected_pricing : ""
                }
              >
                <span>{t("For Groups")}</span>
                <span className={styles.pricing_text}>
                  {" "}
                  <span>
                    Rs {isSeries ? price?.watchPartySeason : price?.watchParty}
                  </span>{" "}
                  /{t("per ticket")}
                </span>
              </div>
            )}
          </div>
        </div>

        {/* helper text */}
        {/* <p className={styles.price_helper_text}>
          {t("Bluetooth earphones/ headphones connection with phone required.")}
        </p>

        <div>{t("Prices may change according to device type")}</div> */}

        {/* watch free box */}
        {(isSeries ? currentSeason.isAvod : isAvod) ? (
          <div className={styles.watch_free_box}>
            <div>
              <button
                className={`${styles.watch_btn}`}
                onClick={() => setShowAdModal(true)}
              >
                {resumeViewerShipAds != ""
                  ? t("Resume via Ads")
                  : t("Watch for free")}
              </button>
              {/* <span>{t("This has been disabled by artist")}</span> */}
            </div>
          </div>
        ) : (
          ""
        )}

        {/* creator follow box */}
        {/* <div className={styles.creators_folow_box}> */}
        {/* <div> */}
        {/* <h4> */}
        {/* {t("Uploaded by artist")} {detailedData.uploadedBy} */}
        {/* </h4> */}
        {/* <button className={styles.follow_btn}>{t("Follow")}</button> */}
        {/* </div> */}
        {/* <span>
            {t("Get latest uploads from the artist while you support")}
          </span> */}
        {/* </div> */}
      </div>

      <Modal
        show={showPaymentOptions}
        onHide={handleClosePaymentOptionModal}
        // backdrop="static"
        centered
      >
        <Modal.Header className={styles.price_modal_container}>
          <Row>
            <Col xs="auto">
              <Modal.Title> Select Payment Options</Modal.Title>
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <svg
                viewBox="-0.5 0 25 25"
                fill="none"
                style={{ cursor: "pointer" }}
                width={"20px"}
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleClosePaymentOptionModal()}
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M3 21.32L21 3.32001"
                    stroke="#017bf4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M3 3.32001L21 21.32"
                    stroke="#017bf4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </Col>
          </Row>
          <br />
          <button
            className={styles.payment_btns}
            onClick={() =>
              handlePayWatchPartyFromWallet(
                isSeries ? price?.watchPartySeason : price?.watchParty,
                getDeviceType()
              )
            }
            disabled={walletload}
            style={{ opacity: walletload ? "0.7" : 1 }}
          >
            Pay from Wallet
          </button>

          <button
            className={styles.payment_btns}
            disabled={walletload}
            style={{ opacity: walletload ? "0.7" : 1 }}
            onClick={() =>
              handlePayWatchParty(
                1,
                isSeries ? "watchPartySeason" : "watchParty",
                isSeries ? price?.watchPartySeason : price?.watchParty,
                getDeviceType()
              )
            }
          >
            {" "}
            {t("Pay from UPI")}{" "}
          </button>
        </Modal.Header>
      </Modal>

      <Modal
        show={showPriceModal}
        onHide={handleClosePriceModal}
        backdrop="static"
        centered
      >
        {selectedPricing == "individual" ? (
          <Modal.Header className={styles.price_modal_container}>
            <Row>
              <Col>
                <Modal.Title>Select Pricing (INR) </Modal.Title>
              </Col>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <svg
                  viewBox="-0.5 0 25 25"
                  fill="none"
                  style={{ cursor: "pointer" }}
                  width={"20px"}
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleClosePriceModal()}
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M3 21.32L21 3.32001"
                      stroke="#017bf4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                    <path
                      d="M3 3.32001L21 21.32"
                      stroke="#017bf4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
                {/* <button
                  className={`${styles.watch_btn}`}
                  onClick={() => handleClosePriceModal()}
                >
                  {t("Close Btn")}
                </button> */}
              </Col>
            </Row>
            <hr />

            <Row>
              <Col>
                <h6> Device Type</h6>
              </Col>
              <Col>
                <h6> Price</h6>
              </Col>
              <Col></Col>
            </Row>
            <Col>
              <Row>
                <Col>
                  <h6> Tv's</h6>
                </Col>
                <Col>
                  {isSeries
                    ? currentSeason?.devicePricing?.tv?.ppeSeason
                    : devicePricing?.tv?.ppe}
                </Col>
                <Col>
                  <button
                    className={styles.follow_btn}
                    onClick={() =>
                      openViewerShipScreen(
                        "individual",
                        1,
                        "ppe",
                        isSeries
                          ? currentSeason?.devicePricing?.tv?.ppeSeason
                          : devicePricing?.tv?.ppe,
                        "tv"
                      )
                    }
                  >
                    {t("Pay")}
                  </button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <h6> laptops & Desktop</h6>
                </Col>
                <Col>
                  {isSeries
                    ? currentSeason?.devicePricing?.web?.ppeSeason
                    : devicePricing?.web?.ppe}
                </Col>
                <Col>
                  <button
                    className={styles.follow_btn}
                    onClick={() =>
                      openViewerShipScreen(
                        "individual",
                        1,
                        "ppe",
                        isSeries
                          ? currentSeason?.devicePricing?.web?.ppeSeason
                          : devicePricing?.web?.ppe,
                        "web"
                      )
                    }
                  >
                    {t("Pay")}
                  </button>
                </Col>
              </Row>
            </Col>
            <Row>
              <Col>
                <h6>Mobile</h6>
              </Col>
              <Col>
                {isSeries
                  ? currentSeason?.devicePricing?.mobile?.ppeSeason
                  : devicePricing?.mobile?.ppe}
              </Col>
              <Col>
                <button
                  className={styles.follow_btn}
                  onClick={() =>
                    openViewerShipScreen(
                      "individual",
                      1,
                      "ppe",
                      isSeries
                        ? currentSeason?.devicePricing?.mobile?.ppeSeason
                        : devicePricing?.mobile?.ppe,
                      "mobile"
                    )
                  }
                >
                  {t("Pay")}
                </button>
              </Col>
            </Row>
          </Modal.Header>
        ) : (
          <Modal.Header className={styles.price_modal_container}>
            <Row>
              <Col>
                <Modal.Title>Select Pricing (INR) </Modal.Title>
              </Col>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <svg
                  viewBox="-0.5 0 25 25"
                  fill="none"
                  style={{ cursor: "pointer" }}
                  width={"20px"}
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleClosePriceModal()}
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M3 21.32L21 3.32001"
                      stroke="#50e4ad"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                    <path
                      d="M3 3.32001L21 21.32"
                      stroke="#50e4ad"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
                {/* <button
                  className={`${styles.watch_btn}`}
                  onClick={() => handleClosePriceModal()}
                >
                  {t("Close Btn")}
                </button> */}
              </Col>
            </Row>
            <hr />

            <Row>
              <Col>
                <h6> Device Type</h6>
              </Col>
              <Col>
                <h6> Price</h6>
              </Col>
              <Col></Col>
            </Row>

            <Row>
              <Col>
                <h6> Tv's</h6>
              </Col>
              <Col>
                {isSeries
                  ? currentSeason?.devicePricing?.tv?.watchPartySeason
                  : devicePricing?.tv?.watchParty}
              </Col>
              <Col>
                <button
                  className={styles.follow_btn}
                  onClick={() =>
                    openViewerShipScreen(
                      "individual",
                      1,
                      "watchParty",
                      isSeries
                        ? currentSeason?.devicePricing?.tv?.watchPartySeason
                        : devicePricing?.tv?.watchParty,
                      "tv"
                    )
                  }
                >
                  {t("Pay")}
                </button>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6> laptops & Desktop</h6>
              </Col>
              <Col>
                {isSeries
                  ? currentSeason?.devicePricing?.web?.watchPartySeason
                  : devicePricing?.web?.watchParty}
              </Col>
              <Col>
                <button
                  className={styles.follow_btn}
                  onClick={() =>
                    openViewerShipScreen(
                      "individual",
                      1,
                      "watchParty",
                      isSeries
                        ? currentSeason?.devicePricing?.web?.watchPartySeason
                        : devicePricing?.web?.watchParty,
                      "web"
                    )
                  }
                >
                  {t("Pay")}
                </button>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6> Mobile</h6>
              </Col>
              <Col>
                {isSeries
                  ? currentSeason?.devicePricing?.mobile?.watchPartySeason
                  : devicePricing?.mobile?.watchParty}
              </Col>
              <Col>
                <button
                  className={styles.follow_btn}
                  onClick={() =>
                    openViewerShipScreen(
                      "individual",
                      1,
                      "watchParty",
                      isSeries
                        ? currentSeason?.devicePricing?.mobile?.watchPartySeason
                        : devicePricing?.mobile?.watchParty,
                      "mobile"
                    )
                  }
                >
                  {t("Pay")}
                </button>
              </Col>
            </Row>
          </Modal.Header>
        )}
      </Modal>

      <Modal
        show={showAdModal}
        onHide={() => setShowAdModal(false)}
        // backdrop="static"
        centered
      >
        <Modal.Header className={styles.price_modal_container}>
          <Row>
            <Col>Watch for Free with Ads</Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <svg
                viewBox="-0.5 0 25 25"
                fill="none"
                style={{ cursor: "pointer" }}
                width={"20px"}
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setShowAdModal(false)}
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M3 21.32L21 3.32001"
                    stroke="#017bf4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M3 3.32001L21 21.32"
                    stroke="#017bf4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </Col>
          </Row>
          To keep this content free for you, we need to show ads. These ads help
          us cover the ticket price so we can support creators and bring you
          more amazing content. Enjoy your show! 🎬🍿
          <button className={styles.payment_btns} onClick={handlePayViaAds}>
            Watch for free
          </button>
        </Modal.Header>
        {/* <Modal.Footer className={styles.price_modal_footer}></Modal.Footer> */}
      </Modal>
    </>
  );
};
