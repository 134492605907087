export const GET_HOME_DATA_BANNER_REQ = "GET_HOME_DATA_BANNER_REQ";
export const GET_HOME_DATA_BANNER_SUCC = "GET_HOME_DATA_BANNER_SUCC";
export const GET_HOME_DATA_BANNER_ERR = "GET_HOME_DATA_BANNER_ERR";

export const GET_HOME_DATA_CONTINUE_WATCHING_REQ =
  "GET_HOME_DATA_CONTINUE_WATCHING_REQ";
export const GET_HOME_DATA_CONTINUE_WATCHING_SUCC =
  "GET_HOME_DATA_CONTINUE_WATCHING_SUCC";
export const GET_HOME_DATA_CONTINUE_WATCHING_ERR =
  "GET_HOME_DATA_CONTINUE_WATCHING_ERR";

export const GET_HOME_DATA_WHATS_NEW_REQ = "GET_HOME_DATA_WHATS_NEW_REQ";
export const GET_HOME_DATA_WHATS_NEW_SUCC = "GET_HOME_DATA_WHATS_NEW_SUCC";
export const GET_HOME_DATA_WHATS_NEW_ERR = "GET_HOME_DATA_WHATS_NEW_ERR";

export const GET_HOME_DATA_TRENDING_NOW_REQ = "GET_HOME_DATA_TRENDING_NOW_REQ";
export const GET_HOME_DATA_TRENDING_NOW_SUCC =
  "GET_HOME_DATA_TRENDING_NOW_SUCC";
export const GET_HOME_DATA_TRENDING_NOW_ERR = "GET_HOME_DATA_TRENDING_NOW_ERR";

export const GET_HOME_DATA_SUGGESTED_FOR_YOU_REQ =
  "GET_HOME_DATA_SUGGESTED_FOR_YOU_REQ";
export const GET_HOME_DATA_SUGGESTED_FOR_YOU_SUCC =
  "GET_HOME_DATA_SUGGESTED_FOR_YOU_SUCC";
export const GET_HOME_DATA_SUGGESTED_FOR_YOU_ERR =
  "GET_HOME_DATA_SUGGESTED_FOR_YOU_ERR";

export const CLEAR_DATA_ERR = "CLEAR_DATA_ERR";
export const CLEAR_DATA = "CLEAR_DATA";
