import React, { useEffect, useState } from "react";
import styles from "./OrderHistory.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { movieBaseUrl } from "../../Api-config/movie";
import axios from "axios";
import wallet_recharge from "../../assets/new-images/History/wallet_recharge.png";
import { FullScreenLoader } from "../../components/fullScreen loader/FullScreenLoader";
import no_result from "../../assets/images/no_result_found.png";
import { generateTokenHandler } from "../../utils/json-web";
export const OrderHistory = ({ getToast, getDeviceType }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [orderItems, setOrderItems] = useState([]);
  const [goldenTickets, setGoldenTicket] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const token = localStorage.getItem("authUser");
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  const navigate = useNavigate();

  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }

  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }
  // getOrderHistory
  const getOrderHistory = async () => {
    setLoader(true);
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `sendFinancialInfo?page=${currentPage}&perPage=10`,
      headers: headers,
    };

    axios
      .request(config)
      .then((res) => {
        setLoader(false);
        console.log("res: ", res.data.data);
        const totalPage = res.data.data[0]?.totalCount[0]?.totalDocuments;
        const orderItem = res.data.data[0]?.transactions;
        if (res.data.status) {
          // set the history of items here
          setOrderItems(orderItem);
          setGoldenTicket(totalPage);
          setTotalPage(Math.ceil(totalPage / 10) || 1);
        } else {
          setTotalPage(1);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          // getToast(
          //   t("Your token is expired. Please login again."),
          //   "top-right",
          //   "error"
          // );
        } else {
          getToast(
            t("Something went wrong on our end. Please try again later."),
            "top-right",
            "error"
          );
        }
      });
  };

  //   handling pagination
  const handlePagination = (v) => {
    console.log(totalPage, currentPage + v);
    setCurrentPage(currentPage + v);
  };

  function convertTimestamp(timestamp = "") {
    const dt = new Date(timestamp);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = dt.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  function paginateArray(array, pageNumber) {
    const itemsPerPage = 5;
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return array.slice(startIndex, endIndex);
  }
  useEffect(() => {
    getOrderHistory();
  }, [currentPage]);

  const type = [
    "Wallet Recharge",
    "Bonus",
    "Video Purchase(Wallet)",
    "Video Purchase",
  ];

  const checkType = (el) => {
    if (el?.videoId == null && el?.ruleApplicationId == null) {
      return "Wallet Recharge";
    } else if (el?.videoId == null && el?.ruleApplicationId) {
      return "Bonus";
    } else if (el?.videoId && el?.walletTransaction) {
      return "Video Purchase(Wallet)";
    } else if (el?.videoId && el?.walletTransaction == false) {
      return "Video Purchase";
    } else {
      return "";
    }
  };

  // videoId==null and ruleAppId ==null recharge
  // videoId==null and ruleAppId!=null bonus
  // videoId and wllet trans --- wallet pay
  // and if wllet false -- direct pay
  //

  return loader ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <FullScreenLoader />
    </div>
  ) : orderItems.length == 0 ? (
    <div className="no_result_box">
      <img src={no_result} alt="no-result-found" />
      <h3>{t("No Transaction History Found")}</h3>
    </div>
  ) : (
    <div className={styles.order_history_container}>
      <h4>{t("Transaction History")}</h4>
      <div className={styles.header_container}></div>

      {/* history box */}
      <div className={styles.historys}>
        <div className={styles.history_header}>
          <div>{t("Content Name")}</div>
          <div>{t("Type")}</div>
          {/* <div>{t("Quantity")}</div> */}
          <div>{t("Amount")}</div>
          <div>{t("Date & Time")}</div>
        </div>
        {orderItems?.map((el, i) => {
          return (
            <div key={i} className={styles.order_history_items}>
              <div className={styles.order_history_posters}>
                <img
                  src={
                    checkType(el) != "Wallet Recharge" &&
                    checkType(el) != "Bonus"
                      ? // checkType(el) != "Bonus"
                        el?.keyArt
                      : wallet_recharge
                  }
                  alt={checkType(el)}
                />
                <p>{el?.videoTitle}</p>
              </div>
              <div
                className={
                  checkType(el) == "Bonus"
                    ? styles.history_transfer_type_video_bonus
                    : checkType(el) == "Wallet Recharge"
                    ? styles.history_transfer_type_video_recharge
                    : checkType(el) == "Video Purchase" ||
                      checkType(el) == "Video Purchase(Wallet)"
                    ? styles.history_transfer_type_video_purchase
                    : ""
                }
              >
                {checkType(el)}
              </div>
              <div>Rs {el?.amount}</div>
              <div>{convertTimestamp(el?.transactionDate)}</div>
            </div>
          );
        })}
      </div>

      {/* pagination comp */}
      <div className={styles.pagination_container}>
        <button
          disabled={currentPage == 1}
          onClick={() => handlePagination(-1)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path d="M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z" />
          </svg>
        </button>

        {currentPage > 2 && (
          <>
            <div
              onClick={() => setCurrentPage(1)}
              className={1 == currentPage ? styles.activePage : ""}
            >
              1
            </div>
            <span>...</span>
          </>
        )}

        {Array.from(
          { length: Math.min(5, totalPage) },
          (_, i) => currentPage - 2 + i + 1
        )
          .filter((pageNumber) => pageNumber >= 1 && pageNumber < totalPage)
          .map((pageNumber) => (
            <div
              key={pageNumber}
              className={`${styles.pagination_button} ${
                pageNumber === currentPage ? styles.activePage : ""
              }`}
              onClick={() => setCurrentPage(pageNumber)}
            >
              {pageNumber}
            </div>
          ))}

        {currentPage <= totalPage && (
          <>
            <span>...</span>
            <div
              className={`${styles.pagination_button} ${
                totalPage === currentPage ? styles.activePage : ""
              }`}
              onClick={() => setCurrentPage(totalPage)}
            >
              {totalPage}
            </div>
          </>
        )}

        <button
          disabled={currentPage == totalPage}
          onClick={() => handlePagination(1)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
          </svg>
        </button>
      </div>
    </div>
  );
};
