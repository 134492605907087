import * as types from "./actionType";

const intialData = {
  trendingData: {
    all: [],
    allError: false,
    allLoading: false,
    movie: [],
    movieError: false,
    movieLoading: false,
    documentary: [],
    documentaryError: false,
    documentaryLoading: false,
    tvSeries: [],
    tvSeriesError: false,
    tvSeriesLoading: false,
    miniSeries: [],
    miniSeriesError: false,
    miniSeriesLoading: false,
    podcast: [],
    podcastError: false,
    podcastLoading: false,
  },
  bannerIsLoading: false,
  bannerData: [],
  bannerDataError: false,
  continueWatchingData: {
    all: [],
    allError: false,
    allLoading: false,
    movie: [],
    movieError: false,
    movieLoading: false,
    documentary: [],
    documentaryError: false,
    documentaryLoading: false,
    tvSeries: [],
    tvSeriesError: false,
    tvSeriesLoading: false,
    miniSeries: [],
    miniSeriesError: false,
    miniSeriesLoading: false,
    podcast: [],
    podcastError: false,
    podcastLoading: false,
  },
  whatsNewData: {
    all: [],
    allError: false,
    allLoading: false,
    movie: [],
    movieError: false,
    movieLoading: false,
    documentary: [],
    documentaryError: false,
    documentaryLoading: false,
    tvSeries: [],
    tvSeriesError: false,
    tvSeriesLoading: false,
    miniSeries: [],
    miniSeriesError: false,
    miniSeriesLoading: false,
    podcast: [],
    podcastError: false,
    podcastLoading: false,
  },
  suggestedData: {
    all: [],
    allError: false,
    allLoading: false,
    movie: [],
    movieError: false,
    movieLoading: false,
    documentary: [],
    documentaryError: false,
    documentaryLoading: false,
    tvSeries: [],
    tvSeriesError: false,
    tvSeriesLoading: false,
    miniSeries: [],
    miniSeriesError: false,
    miniSeriesLoading: false,
    podcast: [],
    podcastError: false,
    podcastLoading: false,
  },
  isError: false,
};

export const reducer = (state = intialData, action) => {
  switch (action.type) {
    case types.GET_HOME_DATA_TRENDING_NOW_REQ:
      return {
        ...state,
        trendingData: {
          ...state.trendingData,
          [`${action.SLUG}Loading`]: true,
        },
      };
    case types.GET_HOME_DATA_TRENDING_NOW_SUCC:
      console.log("action", action);

      return {
        ...state,
        trendingData: {
          ...state.trendingData,
          [action.SLUG]: action?.payload,
          [`${action.SLUG}Loading`]: false,
        },
      };
    case types.GET_HOME_DATA_TRENDING_NOW_ERR:
      return {
        ...state,
        trendingData: {
          ...state.trendingData,

          [`${action.SLUG}Loading`]: false,
          [`${action.SLUG}Error`]: true,
          [action.SLUG]: [],
        },
      };
    case types.GET_HOME_DATA_BANNER_REQ:
      return {
        ...state,
        bannerIsLoading: true,
      };
    case types.GET_HOME_DATA_BANNER_SUCC:
      return {
        ...state,
        bannerData: action.payload,
        bannerIsLoading: false,
      };
    case types.GET_HOME_DATA_BANNER_ERR:
      return {
        ...state,
        bannerDataError: true,
        bannerIsLoading: false,
      };
    case types.GET_HOME_DATA_CONTINUE_WATCHING_REQ:
      return {
        ...state,
        continueWatchingData: {
          ...state.continueWatchingData,
          [`${action.SLUG}Loading`]: true,
        },
      };
    case types.GET_HOME_DATA_CONTINUE_WATCHING_SUCC:
      return {
        ...state,
        continueWatchingData: {
          ...state.continueWatchingData,
          [action.SLUG]: action.payload,
          [`${action.SLUG}Loading`]: false,
        },
      };
    case types.GET_HOME_DATA_CONTINUE_WATCHING_ERR:
      return {
        ...state,
        continueWatchingData: {
          ...state.continueWatchingData,

          [`${action.SLUG}Loading`]: false,
          [`${action.SLUG}Error`]: true,
          [action.SLUG]: [],
        },
      };
    case types.GET_HOME_DATA_WHATS_NEW_REQ:
      return {
        ...state,
        whatsNewData: {
          ...state.whatsNewData,
          [`${action.SLUG}Loading`]: true,
        },
      };
    case types.GET_HOME_DATA_WHATS_NEW_SUCC:
      return {
        ...state,
        whatsNewData: {
          ...state.whatsNewData,
          [action.SLUG]: action.payload,
          [`${action.SLUG}Loading`]: false,
        },
      };
    case types.GET_HOME_DATA_WHATS_NEW_ERR:
      return {
        ...state,
        whatsNewData: {
          ...state.whatsNewData,

          [`${action.SLUG}Loading`]: false,
          [`${action.SLUG}Error`]: true,
          [action.SLUG]: [],
        },
      };
    case types.GET_HOME_DATA_SUGGESTED_FOR_YOU_REQ:
      return {
        ...state,
        suggestedData: {
          ...state.suggestedData,
          [`${action.SLUG}Loading`]: true,
        },
      };
    case types.GET_HOME_DATA_SUGGESTED_FOR_YOU_SUCC:
      return {
        ...state,
        suggestedData: {
          ...state.suggestedData,
          [action.SLUG]: action.payload,
          [`${action.SLUG}Loading`]: false,
        },
      };
    case types.GET_HOME_DATA_SUGGESTED_FOR_YOU_ERR:
      return {
        ...state,
        suggestedData: {
          ...state.suggestedData,
          [action.SLUG]: [],
          [`${action.SLUG}Loading`]: false,
          [`${action.SLUG}Error`]: true,
        },
      };
    case types.CLEAR_DATA_ERR:
      return {
        ...state,
        isError: false,
      };
    default:
      return state;
  }
};
