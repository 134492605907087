import * as types from "./actionType";

export const getHomeDataBannerReq = () => {
  return {
    type: types.GET_HOME_DATA_BANNER_REQ,
  };
};

export const getHomeDataBannerSuccess = (pa) => {
  return {
    type: types.GET_HOME_DATA_BANNER_SUCC,
    payload: pa,
  };
};

export const getHomeDataBannerError = () => {
  return {
    type: types.GET_HOME_DATA_BANNER_ERR,
  };
};

export const getHomeDataContinueWatchingReq = (slug) => {
  return {
    type: types.GET_HOME_DATA_CONTINUE_WATCHING_REQ,
    SLUG: slug,
  };
};

export const getHomeDataContinueWatchingSuccess = (pa) => {
  return {
    type: types.GET_HOME_DATA_CONTINUE_WATCHING_SUCC,
    payload: pa?.data,
    SLUG: pa?.slug,
  };
};

export const getHomeDataContinueWatchingError = (slug) => {
  return {
    type: types.GET_HOME_DATA_CONTINUE_WATCHING_ERR,
    SLUG: slug,
  };
};

export const getHomeDataWhatsNewReq = (slug) => {
  return {
    type: types.GET_HOME_DATA_WHATS_NEW_REQ,
    SLUG: slug,
  };
};

export const getHomeDataWhatsNewSuccess = (pa) => {
  return {
    type: types.GET_HOME_DATA_WHATS_NEW_SUCC,
    payload: pa?.data,
    SLUG: pa?.slug,
  };
};

export const getHomeDataWhatsNewError = (slug) => {
  return {
    type: types.GET_HOME_DATA_WHATS_NEW_ERR,
    SLUG: slug,
  };
};

export const getHomeDataTrendingNowReq = (slug) => {
  return {
    type: types.GET_HOME_DATA_TRENDING_NOW_REQ,
    SLUG: slug,
  };
};

export const getHomeDataTrendingNowSuccess = (pa) => {
  return {
    type: types.GET_HOME_DATA_TRENDING_NOW_SUCC,
    payload: pa?.data,
    SLUG: pa?.slug,
  };
};

export const getHomeDataTrendingNowError = (slug) => {
  return {
    type: types.GET_HOME_DATA_TRENDING_NOW_ERR,
    SLUG: slug,
  };
};

export const getHomeDataSuggestedForYouReq = (slug) => {
  return {
    type: types.GET_HOME_DATA_SUGGESTED_FOR_YOU_REQ,
    SLUG: slug,
  };
};

export const getHomeDataSuggestedForYouSuccess = (pa) => {
  return {
    type: types.GET_HOME_DATA_SUGGESTED_FOR_YOU_SUCC,
    payload: pa?.data,
    SLUG: pa?.slug,
  };
};

export const getHomeDataSuggestedForYouError = (slug) => {
  return {
    type: types.GET_HOME_DATA_SUGGESTED_FOR_YOU_ERR,
    SLUG: slug,
  };
};
export const clearDataError = (slug) => {
  return {
    type: types.CLEAR_DATA_ERR,
    SLUG: slug,
  };
};
export const clearData = () => {
  return {
    type: types.CLEAR_DATA,
  };
};
