import React, { useEffect, useState } from "react";
import styles from "./BuyTicketsForEpisode.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { movieBaseUrl } from "../../../Api-config/movie";
import axios from "axios";

import { v4 as uuidv4 } from "uuid";
import { generateTokenHandler } from "../../../utils/json-web";
import { checkPaymentStatusForEpisodes } from "../../../utils/checkContentPurchaseStatus";

const BuyTicketsForEpisode = ({
  detailedData,
  getToast,
  deviceDetailsFromApp,
  paymentStatus = {},
  getDeviceType,
  seasonNumber,
  episode,
}) => {
  const { devicePricing, isSeries, seasons, category, videoId } = detailedData;

  const [price, setPrice] = useState(devicePricing);
  console.log("jdfkjsdfjsdjfdjsdjfsdjfsdjfsdjkf", price);
  const [paymentDetails, setPaymentDetails] = useState(null);

  const [showPaymentOptions, setPaymentOptions] = useState(false);
  const handleClosePaymentOptionModal = () => {
    setPaymentOptions(false);
  };
  // const deviceDetailsFromApp = parseCustomJsonString(
  //   window.deviceDetails || localStorage.getItem("deviceDetails")
  // );
  // getting favourites from storage;
  let favourites = JSON.parse(localStorage.getItem("favourites")) || [];

  const [isFav, setIsFav] = useState(
    favourites.includes(detailedData?.videoId)
  );

  const [currentSeason, setCurrentSeason] = useState(
    isSeries
      ? seasons?.find((item) => item?.seasonNumber == seasonNumber) || {}
      : {}
  );
  console.log("currentSeason: ", currentSeason);

  useEffect(() => {
    setCurrentSeason(
      seasons?.find((item) => item?.seasonNumber == seasonNumber) || {}
    );
  }, [seasonNumber]);

  const { t } = useTranslation();
  const [selectedPricing, setSelectingPricing] = useState("groups");
  const authUser = localStorage.getItem("authUser");

  const navigate = useNavigate();
  const [showPriceModal, setPriceModal] = useState(false);
  const [currentDevicePrice, setCurrentDevicePrice] = useState("");
  const [walletload, setWalletload] = useState(false);

  const handleClosePriceModal = () => {
    setPriceModal(false);
  };
  const userAgent = window.navigator.userAgent.toLowerCase();

  // function getDeviceType() {
  //   if (window.innerWidth >= 1800) {
  //     return "tv";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     // 768×1024
  //     userAgent.includes("macos") ||
  //     window.innerHeight >= 1024 ||
  //     window.innerWidth >= 768
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "mobile";
  //   }
  // }
  const openViewerShipScreen = (
    type,
    viewType,
    priceType,
    amount,
    payDevice
  ) => {
    // viewer Id

    const viewerId = localStorage.getItem("viewerId");
    sessionStorage.setItem("primaryDevice", "yes");
    const detailsForPayScreen = {
      viewType,
      priceType,
      payDevice,
      amount,
      isSeries: isSeries,
      category: category,
      episode: episode ? episode : null,
      deviceType: getDeviceType(),
      // seasonNumber: isSeries ? currentSeason?.seasonNumber : null,
      seasonNumber: seasonNumber ? seasonNumber : null,
      videoId: detailedData.videoId,
    };
    console.log(detailsForPayScreen);
    // setSelectingPricing(type);
    setPriceModal(false);
    console.log("going to flutter app", type);
    const messageData = {
      authUser,
      detailsForPayScreen,
      deviceType: getDeviceType(),
      detailedData,
    };
    if (priceType == "ppe") {
      console.log("navigating to ppe", priceType);
      sessionStorage.setItem("watchType", "ppe");
      messageData.viewerId = viewerId;

      var jsonString = JSON.stringify(messageData);
      var encodedJsonString = encodeURIComponent(jsonString);
      console.log("encodedJsonString: ", encodedJsonString);
      console.log(
        "deviceDetailsFromApp?.deviceId: ",
        deviceDetailsFromApp?.deviceId
      );
      window?.flutter_inappwebview?.callHandler("navigateToppe", messageData);
      if (
        deviceDetailsFromApp?.deviceId &&
        (messageData.deviceType == "laptop" || messageData.deviceType == "tv")
      ) {
        window.location.href = `app://ppe?data=${encodedJsonString}`;
      }
    } else if (priceType == "watchParty") {
      console.log("navigating to watchParty", priceType);
      sessionStorage.setItem("watchType", "watchParty");
      messageData.viewerId = viewerId;

      var jsonString = JSON.stringify(messageData);
      var encodedJsonString = encodeURIComponent(jsonString);
      console.log(
        "deviceDetailsFromApp?.deviceId: ",
        deviceDetailsFromApp?.deviceId
      );
      window?.flutter_inappwebview?.callHandler(
        "navigateTowatchParty",
        messageData
      );
      if (
        deviceDetailsFromApp?.deviceId &&
        (messageData.deviceType == "laptop" || messageData.deviceType == "tv")
      ) {
        window.location.href = `app://navigateTowatchParty?data=${encodedJsonString}`;
      }
    }
  };

  const handleClickPrice = (type, viewType, priceType, amount, payDevice) => {
    console.log("type: ", type);
    // setSelectingPricing(type);
    if (type == "individual") {
      if (!deviceDetailsFromApp) {
        // getToast(t("This feature is coming soon."), "top-right", "info");
        return;
      }
      return;
    }

    // openViewerShipScreen("individual", viewType, priceType, amount, payDevice);
    setPaymentOptions(true);
    // handlePayWatchParty(1, "watchParty", amount, payDevice);

    // setPriceModal(true);
    // navigate("/buy_tickets/add_buddy");
  };

  // for add viewer to viewer ship
  const handleAddViewer = async (viewerShipId, viewerId) => {
    const slug = await generateTokenHandler();
    try {
      let headers = {
        authorization: authUser,
        devicetype: getDeviceType(),
        slug: slug,
      };
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: movieBaseUrl + "addViewer/" + viewerShipId + "/connected",
        headers: headers,
      };
      axios.request(config).then((res) => {
        console.log(res.data);
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  // handle pay for watchparty
  const handlePayWatchParty = async (
    viewType,
    priceType,
    amount,
    payDevice
  ) => {
    const viewerId = localStorage.getItem("viewerId");
    const viewerShipId = uuidv4();
    setWalletload(true);
    handleAddViewer(viewerShipId, viewerId);
    let slug = await generateTokenHandler();
    try {
      let headers = {
        authorization: authUser,
        slug: slug,
        devicetype: getDeviceType(),
      };
      const data = {
        viewerShipId: viewerShipId,
        viewerIds: [viewerId],
        amount: amount,
        videoId: videoId,
        transactionDate: new Date(),
        videoDetails: {
          viewType: 1,
          priceType: "watchPartyPerEpisode",
          amount: amount,
          payDevice: payDevice,
          videoId: videoId,
          isSeries: isSeries,
          deviceType: payDevice,
          episodeNumber: isSeries && episode ? episode : null,
          seasonNumber: isSeries && seasonNumber ? seasonNumber : null,
          numberOfViewers: 1,
        },
      };
      console.log("data: ", data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: movieBaseUrl + "pay_page",
        headers: headers,
        data: data,
      };
      axios
        .request(config)
        .then((res) => {
          console.log("res: ", res.data.data);
          if (res.data.status && res.data.response_code == 200) {
            getToast(res.data.message, "top-right", "success");
            const reddirectUrl =
              res.data["data"]["data"]["instrumentResponse"]["redirectInfo"][
                "url"
              ];

            sessionStorage.setItem(
              "transactionId",
              res.data["data"]["data"]["merchantTransactionId"]
            );
            console.log("reddirectUrl: ", reddirectUrl);
            sessionStorage.setItem("videoDetails", videoId);
            sessionStorage.setItem("viewerShipId", viewerShipId);
            if (isSeries) {
              sessionStorage.setItem("seasonNumber", seasonNumber);
              sessionStorage.setItem("episodeNumber", episode);
            }

            localStorage.setItem("playWithAds", false);
            localStorage.setItem(
              "deviceDetails",
              JSON.stringify({
                deviceType: getDeviceType(),
              })
            );
            sessionStorage.setItem("watchType", "watchPartyPerEpisode");
            setWalletload(false);
            window.location.href = reddirectUrl;
            // navigate("/paymentPending");
          }
        })
        .catch((err) => {
          setWalletload(false);
          if (err?.response?.data?.response_code == 401) {
            navigate("/signin");
            localStorage.clear();
            getToast(
              t("Your Session Has Expired. Please login again."),
              "top-right",
              "error"
            );
          } else {
            getToast(
              t("Something went wrong on our end. Please try again later."),
              "top-right",
              "error"
            );
          }
        });
    } catch (error) {
      setWalletload(false);
      console.log("error: ", error);
    }
  };

  // handle paye for watchparty via wallet
  const handlePayWatchPartyFromWallet = async (amount, payDevice) => {
    const viewerId = localStorage.getItem("viewerId");
    const viewerShipId = uuidv4();
    setWalletload(true);
    handleAddViewer(viewerShipId, viewerId);
    let slug = await generateTokenHandler();
    try {
      let headers = {
        authorization: authUser,
        devicetype: getDeviceType(),
        slug: slug,
      };
      const data = {
        viewerShipId: viewerShipId,
        viewerIds: [viewerId],
        amount: amount,
        videoId: videoId,
        transactionDate: new Date(),
        videoDetails: {
          viewType: 1,
          priceType: "watchPartyPerEpisode",
          amount: amount,
          payDevice: payDevice,
          deviceType: payDevice,
          videoId: videoId,
          isSeries: isSeries,
          // episode: null,
          // seasonNumber: null,
          episodeNumber: isSeries && episode ? episode : null,
          seasonNumber: isSeries && seasonNumber ? seasonNumber : null,
          numberOfViewers: 1,
        },
      };
      console.log("data: ", data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: movieBaseUrl + "pay/wallet",
        headers: headers,
        data: data,
      };
      axios
        .request(config)
        .then((res) => {
          console.log("res: ", res.data.data);
          if (res.data.status && res.data.response_code == 200) {
            getToast(res.data.message, "top-right", "success");

            sessionStorage.setItem("videoDetails", videoId);
            sessionStorage.setItem("viewerShipId", viewerShipId);
            if (isSeries) {
              sessionStorage.setItem("seasonNumber", seasonNumber);
              sessionStorage.setItem("episodeNumber", episode);
            }
            localStorage.setItem("playWithAds", false);

            localStorage.setItem(
              "deviceDetails",
              JSON.stringify({
                deviceType: getDeviceType(),
              })
            );
            sessionStorage.setItem("watchType", "watchPartyPerEpisode");

            setWalletload(false);
            navigate("/paymentSuccess");
          }
        })
        .catch((err) => {
          setWalletload(false);
          if (err?.response?.data?.response_code == 401) {
            navigate("/signin");
            localStorage.clear();
            // getToast(
            //   t("Your token is expired. Please login again."),
            //   "top-right",
            //   "error"
            // );
          } else {
            getToast(
              t("Something went wrong on our end. Please try again later."),
              "top-right",
              "error"
            );
          }
        });
    } catch (error) {
      setWalletload(false);
      console.log("error: ", error);
    }
  };

  // handle add favourite
  const handleAddFavourite = async () => {
    console.log("add to fav--------");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    console.log(headers.authorization);
    console.log(headers.devicetype);
    console.log(deviceDetailsFromApp);
    console.log(deviceDetailsFromApp?.deviceId);

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + "favourite/add",
      headers: headers,
      data: {
        videoId: detailedData?.videoId,
      },
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        if (res.data.status && res.data.response_code == 200) {
          getToast(res.data.message, "top-right", "success");

          favourites.push(detailedData?.videoId);

          localStorage.setItem("favourites", JSON.stringify(favourites));
          setIsFav(true);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your Session Has Expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(
            t("Something went wrong on our end. Please try again later."),
            "top-right",
            "error"
          );
        }
      });
  };

  // handleresume watching
  const handleResumeWatching = () => {
    const messageData = {
      ...paymentStatus,
      videoId,
      payDevice: paymentStatus.deviceType,
    };
    sessionStorage.setItem("videoDetails", videoId);
    sessionStorage.setItem("viewerShipId", paymentDetails?.viewerShipId);
    localStorage.setItem(
      "deviceDetails",
      JSON.stringify({
        deviceType: getDeviceType(),
      })
    );

    if (isSeries) {
      sessionStorage.setItem("seasonNumber", seasonNumber);
      sessionStorage.setItem("episodeNumber", episode);
    }
    localStorage.setItem("playWithAds", false);
    // sessionStorage.setItem("watchType", "watchParty");
    sessionStorage.setItem(
      "watchType",
      isSeries ? "watchPartyPerEpisode" : "watchParty"
    );

    navigate("/paymentSuccess");
  };

  // get the priceType according to device
  const getPriceAccordingToDevice = (watchType) => {
    const device = getDeviceType();
    if (device == "mobile") {
      if (isSeries) {
        setPrice(currentSeason?.devicePricing?.mobile);
        console.log(
          "currentSeason?.devicePricing?.mobile: ",
          currentSeason?.devicePricing?.mobile
        );
      } else {
        setPrice(devicePricing?.mobile);
      }
    } else if (device == "laptop") {
      if (isSeries) {
        setPrice(currentSeason?.devicePricing?.web);
        console.log(
          "currentSeason?.devicePricing?.web: ",
          currentSeason?.devicePricing?.web
        );
      } else {
        setPrice(devicePricing?.web);
      }
    } else if (device == "tv") {
      if (isSeries) {
        setPrice(currentSeason?.devicePricing?.tv);
        console.log(
          "currentSeason?.devicePricing?.tv: ",
          currentSeason?.devicePricing?.tv
        );
      } else {
        setPrice(devicePricing?.tv);
      }
    }
  };
  console.log(window.innerHeight, window.innerWidth);

  // const getPaymentDevice = (paymentStatusDoc) => {
  //   if (
  //     (paymentStatusDoc?.deviceType == "laptop" &&
  //       getDeviceType() == "mobile") ||
  //     paymentStatusDoc?.amount >=
  //       (isSeries ? price?.watchPartySeason : price?.watchParty)
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  useEffect(() => {
    getPriceAccordingToDevice();
    localStorage.removeItem("deviceDetails");
  }, []);

  useEffect(() => {
    getPriceAccordingToDevice();
    localStorage.removeItem("deviceDetails");
  }, [currentSeason]);

  useEffect(() => {
    setPaymentDetails(
      checkPaymentStatusForEpisodes(
        getDeviceType,
        paymentStatus,
        isSeries,
        seasonNumber,
        episode
      )
    );
  }, [currentSeason, paymentStatus]);

  return (
    <>
      <div className={styles.pricing_container}>
        {/* <button
            className={`${styles.wishlist_btn} ${
              isFav ? styles.color_green : ""
            }`}
            onClick={handleAddFavourite}
            disabled={isFav}
          >
            {isFav ? (
              <svg
                width="26"
                height="24"
                viewBox="0 0 25 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#50e4ad"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z"
                    fill="#50e4ad"
                  ></path>{" "}
                </g>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
              >
                <path
                  d="M0 6.24194C0.0382106 3.09062 2.47717 0.377271 5.5219 0.0593537C7.39422 -0.136583 9.08574 0.351339 10.6673 1.33102C10.9357 1.49719 11.1044 1.48758 11.3672 1.32718C13.219 0.190939 15.1938 -0.344046 17.3336 0.236081C21.1184 1.26283 22.9637 5.14316 21.4977 8.91783C20.8919 10.4776 19.973 11.8069 18.7679 12.9278C16.302 15.2224 13.8257 17.5054 11.3663 19.8087C11.0829 20.0738 10.9292 20.0565 10.6542 19.7991C8.18266 17.4862 5.68406 15.2041 3.21901 12.8846C1.68406 11.44 0.575023 9.71118 0.166822 7.57124C0.082945 7.13422 0.054054 6.68568 0 6.24194ZM6.24884 1.52696C6.08667 1.54521 5.79403 1.57499 5.5014 1.61148C3.00652 1.9198 1.21435 4.35941 1.5685 6.92388C1.83784 8.87365 2.80242 10.4219 4.16962 11.7176C6.32898 13.7634 8.52842 15.7631 10.698 17.7974C10.9525 18.0356 11.082 18.0299 11.3318 17.7955C13.5126 15.7507 15.7157 13.7308 17.8966 11.6859C18.9459 10.7024 19.7363 9.52293 20.1948 8.1312C21.4138 4.42856 18.7605 1.10819 14.9925 1.58459C13.6421 1.75556 12.4651 2.31743 11.3914 3.13576C11.1034 3.35475 10.9189 3.34995 10.6319 3.13C9.37279 2.16376 7.97484 1.56154 6.2479 1.52792L6.24884 1.52696Z"
                  fill="white"
                />
              </svg>
            )}
  
            {isFav ? t("Added to Favourites") : t("Add to your Favourites")}
          </button> */}
        {paymentDetails ? (
          <div className={styles.resume_btn_box}>
            <button
              className={styles.resume_btn}
              onClick={handleResumeWatching}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="15"
                viewBox="0 0 11 15"
                fill="none"
              >
                <path d="M0 15V2.37805V0L11 7.5L0 15Z" />
              </svg>
              {t("Resume")} (For{" "}
              {paymentDetails?.priceType === "watchPartySeason" ||
              "watchPartyPerEpisode"
                ? "Groups"
                : "Individuals"}
              )
            </button>
          </div>
        ) : (
          ""
        )}
        <div className={styles.pricing_box}>
          <h5>{t("Buy Tickets")}</h5>
          <div>
            <OverlayTrigger
              key={"bottom"}
              placement={"bottom"}
              overlay={
                <Tooltip id={`tooltip-${"bottom"}`}>
                  This feature will be coming soon for browser.
                </Tooltip>
              }
            >
              <div
                style={{
                  borderBottomRightRadius:
                    paymentStatus?.priceType == "watchParty" ? "0.5rem" : "",
                }}
                onClick={() =>
                  handleClickPrice(
                    "individual",
                    1,
                    isSeries ? "ppePerEpisode" : "ppe",
                    isSeries ? price?.ppePerEpisode : price?.ppe,
                    getDeviceType()
                  )
                }
                className={styles.disabled_box}
              >
                <span>{t("For Individuals")}</span>
                <span className={styles.pricing_text}>
                  {" "}
                  <span>
                    Rs {isSeries ? price?.ppePerEpisode : price?.ppe}
                  </span>{" "}
                  {t("/per person")}
                </span>
              </div>
            </OverlayTrigger>
            {paymentDetails ? (
              ""
            ) : (
              <div
                style={{
                  borderBottomRightRadius:
                    paymentStatus?.priceType == "ppe" ? "0.5rem" : "",
                }}
                onClick={() =>
                  handleClickPrice(
                    "groups",
                    1,
                    isSeries ? "watchPartyPerEpisode" : "watchParty",
                    isSeries ? price?.watchPartyPerEpisode : price?.watchParty,
                    getDeviceType()
                  )
                }
                className={
                  selectedPricing == "groups" ? styles.selected_pricing : ""
                }
              >
                <span>{t("For Groups")}</span>
                <span className={styles.pricing_text}>
                  {" "}
                  <span>
                    Rs{" "}
                    {isSeries ? price?.watchPartyPerEpisode : price?.watchParty}
                  </span>{" "}
                  /{t("per ticket")}
                </span>
              </div>
            )}
          </div>
        </div>

        {/* helper text */}
        {/* <p className={styles.price_helper_text}>
            {t("Bluetooth earphones/ headphones connection with phone required.")}
          </p>
  
          <div>{t("Prices may change according to device type")}</div> */}

        {/* watch free box */}
        <div className={styles.watch_free_box}>
          {/* <div>
              <button
                className={`${styles.watch_btn} ${styles.disabled_btn}`}
                disabled
              >
                {t("Watch for free")}
              </button>
              <span>{t("This has been disabled by artist")}</span>
            </div> */}
        </div>

        {/* creator follow box */}
        {/* <div className={styles.creators_folow_box}> */}
        {/* <div> */}
        {/* <h4> */}
        {/* {t("Uploaded by artist")} {detailedData.uploadedBy} */}
        {/* </h4> */}
        {/* <button className={styles.follow_btn}>{t("Follow")}</button> */}
        {/* </div> */}
        {/* <span>
              {t("Get latest uploads from the artist while you support")}
            </span> */}
        {/* </div> */}
      </div>

      <Modal
        show={showPaymentOptions}
        onHide={handleClosePaymentOptionModal}
        // backdrop="static"
        centered
      >
        <Modal.Header className={styles.price_modal_container}>
          <Row>
            <Col xs="auto">
              <Modal.Title> Select Payment Options</Modal.Title>
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <svg
                viewBox="-0.5 0 25 25"
                fill="none"
                style={{ cursor: "pointer" }}
                width={"20px"}
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleClosePaymentOptionModal()}
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M3 21.32L21 3.32001"
                    stroke="#017bf4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M3 3.32001L21 21.32"
                    stroke="#017bf4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </Col>
          </Row>
          <br />
          <button
            className={styles.payment_btns}
            onClick={() =>
              handlePayWatchPartyFromWallet(
                isSeries ? price?.watchPartyPerEpisode : price?.watchParty,
                getDeviceType()
              )
            }
            disabled={walletload}
            style={{ opacity: walletload ? "0.7" : 1 }}
          >
            Pay from Wallet
          </button>

          <button
            className={styles.payment_btns}
            disabled={walletload}
            style={{ opacity: walletload ? "0.7" : 1 }}
            onClick={() =>
              handlePayWatchParty(
                1,
                "watchParty",
                isSeries ? price?.watchPartyPerEpisode : price?.watchParty,
                getDeviceType()
              )
            }
          >
            {" "}
            {t("Pay from UPI")}{" "}
          </button>
        </Modal.Header>
      </Modal>

      <Modal
        show={showPriceModal}
        onHide={handleClosePriceModal}
        backdrop="static"
        centered
      >
        {selectedPricing == "individual" ? (
          <Modal.Header className={styles.price_modal_container}>
            <Row>
              <Col>
                <Modal.Title>Select Pricing (INR) </Modal.Title>
              </Col>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <svg
                  viewBox="-0.5 0 25 25"
                  fill="none"
                  style={{ cursor: "pointer" }}
                  width={"20px"}
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleClosePriceModal()}
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M3 21.32L21 3.32001"
                      stroke="#017bf4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                    <path
                      d="M3 3.32001L21 21.32"
                      stroke="#017bf4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
                {/* <button
                    className={`${styles.watch_btn}`}
                    onClick={() => handleClosePriceModal()}
                  >
                    {t("Close Btn")}
                  </button> */}
              </Col>
            </Row>
            <hr />

            <Row>
              <Col>
                <h6> Device Type</h6>
              </Col>
              <Col>
                <h6> Price</h6>
              </Col>
              <Col></Col>
            </Row>
            <Col>
              <Row>
                <Col>
                  <h6> Tv's</h6>
                </Col>
                <Col>
                  {isSeries
                    ? currentSeason?.devicePricing?.tv?.ppeSeason
                    : devicePricing?.tv?.ppe}
                </Col>
                <Col>
                  <button
                    className={styles.follow_btn}
                    onClick={() =>
                      openViewerShipScreen(
                        "individual",
                        1,
                        "ppe",
                        isSeries
                          ? currentSeason?.devicePricing?.tv?.ppeSeason
                          : devicePricing?.tv?.ppe,
                        "tv"
                      )
                    }
                  >
                    {t("Pay")}
                  </button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <h6> laptops & Desktop</h6>
                </Col>
                <Col>
                  {isSeries
                    ? currentSeason?.devicePricing?.web?.ppeSeason
                    : devicePricing?.web?.ppe}
                </Col>
                <Col>
                  <button
                    className={styles.follow_btn}
                    onClick={() =>
                      openViewerShipScreen(
                        "individual",
                        1,
                        "ppe",
                        isSeries
                          ? currentSeason?.devicePricing?.web?.ppeSeason
                          : devicePricing?.web?.ppe,
                        "web"
                      )
                    }
                  >
                    {t("Pay")}
                  </button>
                </Col>
              </Row>
            </Col>
            <Row>
              <Col>
                <h6>Mobile</h6>
              </Col>
              <Col>
                {isSeries
                  ? currentSeason?.devicePricing?.mobile?.ppeSeason
                  : devicePricing?.mobile?.ppe}
              </Col>
              <Col>
                <button
                  className={styles.follow_btn}
                  onClick={() =>
                    openViewerShipScreen(
                      "individual",
                      1,
                      "ppe",
                      isSeries
                        ? currentSeason?.devicePricing?.mobile?.ppeSeason
                        : devicePricing?.mobile?.ppe,
                      "mobile"
                    )
                  }
                >
                  {t("Pay")}
                </button>
              </Col>
            </Row>
          </Modal.Header>
        ) : (
          <Modal.Header className={styles.price_modal_container}>
            <Row>
              <Col>
                <Modal.Title>Select Pricing (INR) </Modal.Title>
              </Col>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <svg
                  viewBox="-0.5 0 25 25"
                  fill="none"
                  style={{ cursor: "pointer" }}
                  width={"20px"}
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleClosePriceModal()}
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M3 21.32L21 3.32001"
                      stroke="#50e4ad"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                    <path
                      d="M3 3.32001L21 21.32"
                      stroke="#50e4ad"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
                {/* <button
                    className={`${styles.watch_btn}`}
                    onClick={() => handleClosePriceModal()}
                  >
                    {t("Close Btn")}
                  </button> */}
              </Col>
            </Row>
            <hr />

            <Row>
              <Col>
                <h6> Device Type</h6>
              </Col>
              <Col>
                <h6> Price</h6>
              </Col>
              <Col></Col>
            </Row>

            <Row>
              <Col>
                <h6> Tv's</h6>
              </Col>
              <Col>
                {isSeries
                  ? currentSeason?.devicePricing?.tv?.watchPartySeason
                  : devicePricing?.tv?.watchParty}
              </Col>
              <Col>
                <button
                  className={styles.follow_btn}
                  onClick={() =>
                    openViewerShipScreen(
                      "individual",
                      1,
                      "watchParty",
                      isSeries
                        ? currentSeason?.devicePricing?.tv?.watchPartySeason
                        : devicePricing?.tv?.watchParty,
                      "tv"
                    )
                  }
                >
                  {t("Pay")}
                </button>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6> laptops & Desktop</h6>
              </Col>
              <Col>
                {isSeries
                  ? currentSeason?.devicePricing?.web?.watchPartySeason
                  : devicePricing?.web?.watchParty}
              </Col>
              <Col>
                <button
                  className={styles.follow_btn}
                  onClick={() =>
                    openViewerShipScreen(
                      "individual",
                      1,
                      "watchParty",
                      isSeries
                        ? currentSeason?.devicePricing?.web?.watchPartySeason
                        : devicePricing?.web?.watchParty,
                      "web"
                    )
                  }
                >
                  {t("Pay")}
                </button>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6> Mobile</h6>
              </Col>
              <Col>
                {isSeries
                  ? currentSeason?.devicePricing?.mobile?.watchPartySeason
                  : devicePricing?.mobile?.watchParty}
              </Col>
              <Col>
                <button
                  className={styles.follow_btn}
                  onClick={() =>
                    openViewerShipScreen(
                      "individual",
                      1,
                      "watchParty",
                      isSeries
                        ? currentSeason?.devicePricing?.mobile?.watchPartySeason
                        : devicePricing?.mobile?.watchParty,
                      "mobile"
                    )
                  }
                >
                  {t("Pay")}
                </button>
              </Col>
            </Row>
          </Modal.Header>
        )}
      </Modal>
    </>
  );
};

export default BuyTicketsForEpisode;
